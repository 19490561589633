<template>
  <div class="section">
    <div class="container">
      <div class="section-title-wide no-bottom-space">
        <h6 class="subheading text-primary-1"> content.preview_text !</h6>
<h1>NOT USED</h1>
        <h1> content.heading </h1>
        <h4> content.sub_header </h4>
        <!-- <video width="400" v-bind:src="content.explainer_video" controls /> -->
        <stripe-checkout
          ref="checkoutRef"
          mode="subscription"
          :pk="publishableKey"
          :line-items="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          :customer-email="customerEmail"
          @loading="(v) => (loading = v)"
        />
        <button @click="subscribe('tier1')">Subscribe!</button>
         <button @click="subscribe('tier2')">Schedule Posting!</button>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_KEY;
    return {
      loading: false,
      lineItems: [
        {
          quantity: 1,
        },
      ],
      successURL: "https://nest.theagentnest.com/almost-there",
      cancelURL: "https://nest.theagentnest.com/stripe-checkout",
      customerEmail:"shane@aclsound.com"
    };
  },
  methods: {
    subscribe(tier) {

       switch (tier) {
        case 'tier1': {
          this.lineItems[0] = {
            price: 'price_1H2s09BfPqBF9CKCUVnoVbXr',
            quantity: 1,
          
          };
          break;
        }
        case 'tier2': {
          this.lineItems[0] = {
            price: 'price_1GxPGhBfPqBF9CKC4417EBRy',
            quantity: 1,
          };
          break;
        }
      };

      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>